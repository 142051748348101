export const FOOTER_GRASS_WIDTH = 50;

export const BREAKPOINTS = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1546,
};

export const mail = "kalareparz@gmail.com";

export const phone = "502 566 411";

export const routes: { name: string; link: string }[] = [
    { name: "Wystawcy", link: "#wystawcy" },
    { name: "Dlaczego my", link: "#dlaczego-my" },
    { name: "Kontakt", link: "#kontakt" },
];
