type Props = {
    className?: string;
};
export const InstagramIcon = ({ className }: Props) => {
    return (
        <svg
            className={className}
            width="683"
            height="683"
            viewBox="0 0 683 683"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_4_28)">
                <path
                    d="M406.667 341.333C406.667 377.417 377.417 406.667 341.333 406.667C305.25 406.667 276 377.417 276 341.333C276 305.25 305.25 276 341.333 276C377.417 276 406.667 305.25 406.667 341.333Z"
                    fill="currentColor"
                />
                <path
                    d="M494.125 225.74C490.984 217.229 485.974 209.526 479.463 203.203C473.141 196.693 465.443 191.682 456.927 188.542C450.021 185.859 439.646 182.667 420.536 181.797C399.865 180.854 393.667 180.651 341.333 180.651C288.995 180.651 282.797 180.849 262.13 181.792C243.021 182.667 232.641 185.859 225.74 188.542C217.224 191.682 209.521 196.693 203.203 203.203C196.693 209.526 191.682 217.224 188.536 225.74C185.854 232.646 182.661 243.026 181.792 262.135C180.849 282.802 180.646 289 180.646 341.339C180.646 393.672 180.849 399.87 181.792 420.542C182.661 439.651 185.854 450.026 188.536 456.932C191.682 465.448 196.687 473.146 203.198 479.469C209.521 485.979 217.219 490.99 225.734 494.13C232.641 496.818 243.021 500.01 262.13 500.88C282.797 501.823 288.99 502.021 341.328 502.021C393.672 502.021 399.87 501.823 420.531 500.88C439.641 500.01 450.021 496.818 456.927 494.13C474.021 487.536 487.531 474.026 494.125 456.932C496.807 450.026 500 439.651 500.875 420.542C501.818 399.87 502.016 393.672 502.016 341.339C502.016 289 501.818 282.802 500.875 262.135C500.005 243.026 496.812 232.646 494.125 225.74ZM341.333 441.979C285.745 441.979 240.682 396.922 240.682 341.333C240.682 285.745 285.745 240.687 341.333 240.687C396.917 240.687 441.979 285.745 441.979 341.333C441.979 396.922 396.917 441.979 341.333 441.979ZM445.958 260.229C432.969 260.229 422.437 249.698 422.437 236.708C422.437 223.719 432.969 213.187 445.958 213.187C458.948 213.187 469.479 223.719 469.479 236.708C469.474 249.698 458.948 260.229 445.958 260.229Z"
                    fill="currentColor"
                />
                <path
                    d="M341.333 0C152.849 0 0 152.849 0 341.333C0 529.818 152.849 682.667 341.333 682.667C529.818 682.667 682.667 529.818 682.667 341.333C682.667 152.849 529.818 0 341.333 0ZM536.151 422.141C535.203 443.005 531.885 457.25 527.042 469.719C516.859 496.047 496.047 516.859 469.719 527.042C457.255 531.885 443.005 535.198 422.146 536.151C401.245 537.104 394.568 537.333 341.339 537.333C288.104 537.333 281.432 537.104 260.526 536.151C239.667 535.198 225.417 531.885 212.953 527.042C199.87 522.12 188.026 514.406 178.234 504.432C168.266 494.646 160.552 482.797 155.63 469.719C150.786 457.255 147.469 443.005 146.521 422.146C145.557 401.24 145.333 394.563 145.333 341.333C145.333 288.104 145.557 281.427 146.516 260.526C147.464 239.661 150.776 225.417 155.62 212.948C160.542 199.87 168.26 188.021 178.234 178.234C188.021 168.26 199.87 160.547 212.948 155.625C225.417 150.781 239.661 147.469 260.526 146.516C281.427 145.563 288.104 145.333 341.333 145.333C394.563 145.333 401.24 145.563 422.141 146.521C443.005 147.469 457.25 150.781 469.719 155.62C482.797 160.542 494.646 168.26 504.438 178.234C514.406 188.026 522.125 199.87 527.042 212.948C531.891 225.417 535.203 239.661 536.156 260.526C537.109 281.427 537.333 288.104 537.333 341.333C537.333 394.563 537.109 401.24 536.151 422.141Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_28">
                    <rect
                        width="682.667"
                        height="682.667"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
